import React from 'react'
import Layout from '../components/layout'

const AGB = () => (
  <Layout>
    <section className="static-site">
        <div className="container layout">
            <div className="content columns">
                <div className="column">
                    <div >
                      <h1><strong>Allgemeine Geschäftsbedingungen</strong></h1>
                      <p>für die Nutzung des Angebots der MeinAlarm24 GmbH, Katharinenstraße 11, 20457 Hamburg</p>
                      <p>(Stand: Juni 2017)</p>
                      <h2 id="Geltungsbereich"><strong>I. Geltungsbereich</strong></h2>
                      <ol>
                        <li>Nachfolgende Geschäftsbedingungen sind Bestandteil aller Angebote und Vertragsannahmeerklärungen des Auftragnehmers und Grundlage aller Verkäufe und Lieferungen des Auftragnehmers einschließlich Beratung und Auskünften. Sie gelten ab Auftragserteilung durch den Auftraggeber als anerkannter Vertragsbestandteil.</li>
                        <li>Entgegenstehenden Allgemeinen Geschäftsbedingungen des Auftraggebers wird hiermit in vollem Umfang widersprochen; sie werden nur mit schriftlicher Bestätigung des Auftragnehmers einmalig im Umfang der Bestätigung abweichend von den vorliegenden AGB Vertragsbestandteil.</li>
                        <li>Bei Ergänzungs- und Folgeaufträgen der unter I. 1 aufgezählten Art gelten diese Allgemeinen Geschäftsbedingungen entsprechend. Sie gelten spätestens ab Auftragserteilung durch den Auftraggeber als anerkannter Vertragsbestandteil.</li>
                        <li>Sofern Lieferungen von Hardware- und Softwareprodukten Gegenstand des Vertrages sind, gelten ergänzend die einschlägigen Bedingungen des Auftragnehmers in der jeweils gültigen Fassung.</li>
                      </ol>
                      <h2 id="Vertragsinhalt"><strong>II. Vertragsinhalt</strong></h2>
                      <ul>
                        <li>Vorvertragliche Mitteilungen, insbesondere Angebote, Beschreibungen, Kostenvoranschläge, sind, außer bei ausdrücklicher Vereinbarung, freibleibend.<br/>
                        Informationen, Angaben in Prospekten, Merkblättern und anwendungstechnischen Hinweisen sollen nur informativ wirken und allgemeine Kenntnis vermitteln. Sofern nicht etwas anderes vereinbart ist, werden sie nicht Vertragsbestandteil.<br/>
                        Für Inhalt und Umfang des Vertrages ist allein die schriftliche Auftragsbestätigung des Auftragnehmers maßgebend.<br/>
                        Vertragsänderungen und mündliche Nebenabreden sind nur bei schriftlicher Bestätigung wirksam.<br/>
                        Gehört der Vertrag zum Betrieb des Handelsgewerbes eines Kaufmanns, ist für Inhalt und Umfang des Vertrages die schriftliche Auftragsbestätigung des Auftragnehmers maßgebend.</li>
                        <li>Der Auftragnehmer behält sich vor, bei Auftragsausführung technische Änderungen vorzunehmen, soweit sie sich aus dem Fortschritt der technischen Entwicklung ergeben oder sich im Einzelfall im Interesse der Leistungsfähigkeit der Anlage als sachdienlich erweisen. Einer vom Vertrag abweichenden oder ergänzenden Berufung des Auftraggebers auf „branchenübliche Gepflogenheiten“ wird hiermit ausdrücklich widersprochen.</li>
                      </ul>
                      <h2 id="Preise"><strong>III. Preise</strong></h2>
                      <ol>
                        <li>Die vom Auftragnehmer angegebenen Preise verstehen sich ohne gesetzliche Mehrwertsteuer, wenn die Mehrwertsteuer nicht ausdrücklich ausgewiesen wurde, beim Kaufvertrag verstehen sich die Preise zudem ab Werk bzw. ab Lager, Verpackung und Montage sind, sofern nicht etwas anderes vereinbart wurde, nicht im Preis enthalten. Sofern sich die gesetzliche Mehrwertsteuer nach Vertragsschluss erhöhen sollte, ist der Auftragnehmer berechtigt, diese im gleichen Umfang zu erhöhen.</li>
                        <li>Ist eine den Auftragnehmer bindende Preisabsprache zustande gekommen, kann dieser, wenn die Leistungen des Auftragnehmers erst mehr als vier Monate nach Vertragsschluss erbracht werden sollen, trotzdem die Preise berichtigen, wenn nachträglich die Lieferung oder Leistung durch neu hinzukommende öffentliche Abgaben, Nebengebühren, Frachten oder deren Erhöhung oder andere gesetzliche Maßnahmen oder eine Änderung der Kostenfaktoren wie Lohn- und Materialkosten, auf denen die Preise des Auftragnehmers beruhen, mittelbar oder unmittelbar betroffen und verteuert wird. Sofern die Preiserhöhung aufgrund der genannten Umstände mehr als 10 % des vereinbarten Preises übersteigt, kann der Auftraggeber vom Vertrag zurücktreten bzw. diesen kündigen. Dies gilt nicht, wenn der Auftragnehmer ausdrücklich und schriftlich einen Festpreis zugesagt hat.</li>
                      </ol>
                      <h2 id="Lieferzeiten, Lieferung, Gefahrenübergang"><strong>IV. Lieferzeiten, Lieferung, Gefahrenübergang</strong></h2>
                      <ol>
                        <li>Die Ausführung bzw. Lieferung beginnt spätestens innerhalb von ca. sechs Wochen nach Vertragsschluss, es sei denn, dass der Auftragnehmer sie ausdrücklich und schriftlich als verbindlich bezeichnet hat. Die Ausführungs- bzw. Lieferzeit beginnt mit dem Tage des Zugangs der vorbehaltlosen Auftragsbestätigung des Auftraggebers beim Auftragnehmer, jedoch nicht vor Klärung aller Ausführungseinzelheiten und Erfüllung aller sonstigen Voraussetzungen, die der Auftraggeber zu erbringen hat.</li>
                        <li>Im Falle höherer Gewalt oder sonstiger außergewöhnlicher und unverschuldeter Umstände, z.B. bei Materialbeschaffungsschwierigkeiten, Betriebsstörungen, Streik, Aussperrung, Mangel an Transportmitteln, behördlichen Eingriffen, Energieversorgungsschwierigkeiten usw. – auch wenn sie bei Vorlieferanten eintreten – verlängert sich, wenn der Auftragnehmer an der rechtzeitigen Erfüllung seiner Verpflichtung behindert ist, die Ausführungs- bzw. Lieferungs-frist um die Dauer der Behinderung sowie einer angemessenen Anlaufzeit. Wird durch die genannten Umstände die Lieferung oder Leistung unmöglich bzw. steht dem Auftragnehmer ein Leistungsverweigerungsrecht aufgrund persönlicher oder praktischer Unzumutbarkeit zu, so wird der Auftragnehmer von der Verpflichtung frei, das Werk zu erstellen bzw. er wird von der Leistungsverpflichtung frei. Sofern die Ausführungsverzögerung länger als zwei Wochen dauert, ist der Auftraggeber berechtigt, vom Vertrag zurückzutreten. Verlängert sich die Aus-führungs- bzw. Leistungszeit oder wird der Auftragnehmer von der Verpflichtung zur Ausführung bzw. Leistung frei, so kann der Auftraggeber hieraus keine Schadensersatzansprüche herleiten. Auf die genannten Umstände kann sich der Auftragnehmer nur berufen, wenn er den Auftraggeber unverzüglich benachrichtigt. Das Recht des Auftraggebers zum Rücktritt nach fruchtlosem Ablauf einer von ihm gesetzten angemessenen Nachfrist bleibt unberührt.</li>
                        <li>Der Auftragnehmer ist zu für den Auftraggeber eigenständig verwendbaren Teilleistungen berechtigt.</li>
                        <li>Bei einem Werkvertrag geht die Gefahr mit Inbetriebnahme des Werks durch den Auftragnehmer, spätestens jedoch mit der Abnahme des Werks auf den Auftraggeber über. Dies gilt auch für Teilabnahmen, sofern diese nach Art und Beschaffenheit des Werks herbeigeführt werden können.<br/>
                        Wird vom Auftraggeber keine Abnahme verlangt, so gilt die Leistung als abgenommen nach Ablauf von 12 Werktagen nach schriftlicher Mitteilung über die Fertigstellung. Vorgenannte Regelungen gelten auch für Teilabnahmen. Wegen geringfügiger Mängel kann die Abnahme nicht verweigert oder verzögert werden.</li>
                        <li>Erfüllungsort bei Abschluss eines Kaufvertrages ist die Niederlassung des Auftragnehmers. Der Auftraggeber trägt die Kosten der Versendung des Kaufgegenstandes ab dem Ort der Niederlassung des Auftragnehmers. Wenn keine Vereinbarungen über den Versand getroffen sind, erfolgt dieser nach Ermessen des Auftragnehmers, wobei der Auftragnehmer nicht verpflichtet ist, die günstigste Versendungsart zu wählen.<br/>
                        Handelt es sich beim Auftraggeber um einen Unternehmer, so geht die Gefahr des Untergangs oder der Beschädigung der Ware auf diesen auch dann über, wenn frachtfreie Lieferung vereinbart worden ist, sobald die Ware das Werk bzw. Lager verlässt. Auf Wunsch des Auftraggebers wird die Ware auf seine Kosten gegen Bruch-, Transportund Feuerschäden versichert.</li>
                        <li>Wenn die Leistung oder Lieferung auf Wunsch des Auftraggebers oder aus von ihm zu vertretenden Gründen (Gläubigerverzug) verzögert wird, so geht die Gefahr für die Zeit der Verzögerung auf den Auftraggeber über. Die entsprechenden Kosten für Wartezeit, Bereitstellung und Aufbewahrung und weitere erforderliche Reisen der Erfüllungsgehilfen des Auftragnehmers hat der Auftraggeber zu tragen.</li>
                      </ol>
                      <h2 id="Errichtung und Instandhaltung von Anlagen"><strong>V. Errichtung und Instandhaltung von Anlagen</strong></h2>
                      <p>Für jede Art von Aufstellung, Montage und Instandhaltung gelten, soweit nicht anders schriftlich vereinbart worden ist, folgende Bestimmungen:<br/>
                      A. Der Auftraggeber hat auf seine Kosten zu übernehmen und rechtzeitig zu stellen:</p>
                      <ol>
                        <li>Hilfsmannschaft wie Handlanger und, wenn nötig, auch Maurer, Zimmerleute, Schlosser, Kranführer, sonstige Facharbeiter mit dem von diesen benötigten Werkzeug in der erforderlichen Zahl, alle Erd-, Bettungs-, Stemm-, Gerüst- Verputz,- Maler- und sonstige branchenfremde Nebenarbeiten einschließlich der dazu benötigten Baustoffe, Betriebskraft und Wasser einschließlich der erforderlichen Anschlüsse bis zur Verwendungsstelle, Heizung und all-gemeine Beleuchtung, bei der Montagestelle für die Aufbewahrung der Maschinenteile, Apparaturen, Materialien, Werkzeuge usw. genügend große, geeignete trockene und verschließbare Räume und für das Montagepersonal angemessene Arbeits- und Aufenthaltsräume einschließlich entsprechender sanitärer Anlagen; im Übrigen hat der Auftraggeber zum Schutz des Auftragnehmers und des Besitzes des Montagepersonals des Auftragnehmers auf der Baustelle die Maßnahmen zu treffen, die er zum Schutz des eigenen Besitzes ergreifen würde. Schutzkleider und Schutzvorrichtungen, die infolge besonderer Umstände der Montagestelle erforderlich und für den Auftragnehmer nicht branchenüblich sind.</li>
                        <li>5 Arbeitstage vor Beginn der Montagearbeiten hat der Auftraggeber die nötigen Angaben über die Lage verdeckt geführter Strom-, Gas-, Wasserleitungen oder ähnlicher Anlagen sowie die erforderlichen statischen Angaben unaufgefordert zur Verfügung zu stellen.</li>
                        <li>Der Auftraggeber verpflichtet sich, den Aufstellern und seinem Montagepersonal die geleisteten Arbeiten nach Wahl des Auftragnehmers täglich oder wöchentlich zu bescheinigen. Er bestätigt ferner auf vom Auftragnehmer gestellten Formularen die Beendigung der Aufstellung oder Montage.</li>
                        <li>Die Kosten der sachgemäßen umweltschutzbedingten Entsorgung von eingebauten Teilen und Komponenten, die ausgebaut oder ersetzt werden müssen, trägt der Auftraggeber.</li>
                      </ol>
                      <p>B. Falls der Auftragnehmer die Montage oder Instandhaltung gegen Einzelberechnung übernommen hat, gelten außer den Bestimmungen unter A noch die nachfolgenden Bedingungen als vereinbart:</p>
                      <ol>
                        <li>Der Auftraggeber vergütet die dem Auftragnehmer bei der Auftragserteilung vereinbarten Verrechnungssätze für Arbeitszeit und Zuschläge für Mehr-, Nacht-, Sonn- und Feiertagsarbeit, für Arbeiten unter erschwerten Umständen sowie für Planung, Überwachung und Dokumentation. Dies gilt entsprechend für den Verbrauch von Material einschließlich Verschnitt sowie für den Aufbau und den Anschluss der Einrichtung.</li>
                        <li>Vorbereitungs-, Reise- und Laufzeiten und Rückmeldungen gelten als Arbeitszeit, wobei für An- und Abfahrten, hierzu zählen insbesondere Lohn- und Fahrzeugkosten, der tatsächliche Aufwand berechnet wird.</li>
                        <li>Ferner werden folgende Kosten gesondert vergütet:<br/>
                        Reisekosten, Kosten für den Transport des Handwerkszeugs und des persönlichen Gepäcks, für Fracht und Verpackung, für die Anlieferung der gesamten Materialien und Geräte sowie bestellte technische Unterlagen; beim Verwender übliche Auslösungen und Zulagen für die Arbeitszeit sowie für Ruhe- und Feiertage.</li>
                      </ol>
                      <p>C. Zur Diagnose und Behebung von zeitweise auftretenden (intermittierenden) Fehlern können wiederholte Überprüfungen und Werkleistungen erforderlich werden. Der Auftraggeber hat insoweit die Kosten auch von mehrmaligen Einsätzen des Auftragnehmers zu tragen.</p>
                      <h2 id="Zahlung"><strong>VI. Zahlung</strong></h2>
                      <ol>
                        <li>Unsere Rechnungen sind 7 Tage nach Rechnungsstellung fällig.</li>
                        <li>Im Falle des Verzuges des Auftraggebers werden unter Vorbehalt der Geltendmachung eines weiteren Schadens Zinsen gemäß § 288 BGB berechnet.</li>
                        <li>Zahlungen können mit schuldbefreiender Wirkung ausschließlich an den Auftragnehmer selbst erfolgen.</li>
                        <li>Die Annahme von Schecks, Wechseln und anderen Wertpapieren erfolgt nur erfüllungshalber unter dem üblichen Vorbehalt ihrer Einlösung, ihrer Diskontierungsmöglichkeit sowie gegen Übernahme sämtlicher, im Zusammenhang mit der Einlösung stehenden Kosten durch den Auftraggeber. Diskont- und Wechselspesen gehen zu Lasten des Auftraggebers und sind sofort fällig.</li>
                        <li>Bei Teilleistungen steht dem Auftragnehmer das Recht auf Verlangen entsprechender Teilzahlungen zu.</li>
                        <li>Alle Forderungen des Auftragnehmers werden unabhängig von der Laufzeit etwa hereingenommener und gutgeschriebener Wechsel sofort fällig, wenn die Zahlungsbedingungen nicht eingehalten oder dem Auftragnehmer Umstände bekannt werden, die geeignet sind, die Kreditwürdigkeit des Auftraggebers zu mindern.</li>
                        <li>Tritt der Auftraggeber vom Vertrag zurück (Abbestellung), ohne dass der Auftragnehmer ihm einen Grund dazu gegeben hat, oder erklärt der Auftraggeber den Rücktritt oder die Kündigung des Vertrages, aus Gründen, die von ihm zu vertreten sind, so verpflichtet er sich, die bereits angefallenen Kosten sowie den entgangenen Gewinn mit einem Pauschalbetrag von max. 30 % des vereinbarten Werklohns zu vergüten. Dem Auftraggeber bleibt der Nachweis vorbehalten, dass Kosten und Gewinn nicht oder nicht in dieser Höhe entstanden bzw. entgangen sind. Danach erfolgt Berechnung nur in nachgewiesener Höhe.</li>
                        <li>Zu einer Aufrechnung ist der Auftraggeber nur berechtigt, wenn die Gegenforderung unbestritten oder rechtskräftig festgestellt worden ist.</li>
                      </ol>
                      <h2 id="Eigentumsvorbehalt"><strong>VII. Eigentumsvorbehalt</strong></h2>
                      <ol>
                        <li>Alle Waren bleiben Eigentum (Vorbehaltsware) des Auftragnehmers bis zur Erfüllung sämtlicher Forderungen, die zum Zeitpunkt des Vertragsschlusses bestanden – bei Zahlung durch Scheck oder Wechsel bis zur Einlösung – gleich aus welchem Rechtsgrund und zwar auch dann, wenn besonders bezeichnete Forderungen bereits beglichen sind.</li>
                        <li>Gehört der Vertrag zum Betrieb des Handelsgewerbes eines Kaufmanns, gilt Satz 1 auch für künftige oder bedingte Forderungen auch aus gleichzeitig oder später abgeschlossenen Verträgen.</li>
                        <li>Der Auftraggeber ist verpflichtet, bezüglich der Vorbehaltsware jegliche Beeinträchtigung des Eigentums zu unterlassen und im Falle des Zugriffs Dritter den Auftragnehmer unverzüglich darüber zu informieren. Diesbezüglich entstehende Kosten von Interventionen trägt der Auftraggeber.</li>
                        <li>Übersteigt der Wert der Sicherheiten die Forderung des Auftragnehmers um mehr als 20 %, so wird dieser auf Verlangen des Auftraggebers insoweit Sicherheiten nach seiner Wahl freigeben.</li>
                      </ol>
                      <h2 id="Ansprüche und Rechte wegen Mängeln"><strong>VIII. Ansprüche und Rechte wegen Mängeln</strong></h2>
                      <ol>
                        <li>Hat der Vertragsgegenstand Mängel, so kann der Auftraggeber zunächst Nacherfüllung (Nachbesserung oder Ersatzlieferung) in angemessener Frist verlangen, wobei dem Auftragnehmer ein Wahlrecht zwischen Nachbesserung oder Ersatzlieferung zusteht. Im Fall der Nachbesserung stehen dem Auftragnehmer zwei Versuche zu.</li>
                        <li>Bei verzögerter, verweigerter oder mehrmalig misslungener Nachbesserung bleibt das Recht auf Rücktritt (Rückgängigmachung des Vertrages) oder Minderung (Herabsetzung der Vergütung) unberührt. Handelt es sich bei dem Auftraggeber um einen Unternehmer, so bestehen bei nur unerheblicher Abweichung von der vereinbarten Beschaffenheit oder bei nur unerheblicher Beeinträchtigung der Brauchbarkeit des Vertragsgegenstandes keine Mängelansprüche. Ist eine Bauleistung Gegenstand der Mängelhaftung, so ist der Rücktritt vom Vertrag ausgeschlossen.<br/>
                        a) Handelt es sich um einen Kaufvertrag, so beträgt die Verjährungsfrist für Nacherfüllung, Rücktritt oder Minderung bei neuen Sachen zwei Jahre, bei gebrauchten Sachen ein Jahr. Die Frist beginnt mit der Lieferung der Kaufsache. Ist der Auftraggeber ein Unternehmer, so beträgt die Verjährungsfrist für neue Kaufsachen ein Jahr, für gebrauchte Sachen sind Nacherfüllung, Rücktritt und Minderung ausgeschlossen.<br/>
                        b) Handelt es sich um einen Werkvertrag, so beträgt die Verjährungsfrist für Nacherfüllung, Rücktritt und Minderung ein Jahr. Die Frist beginnt mit der Abnahme des Werks bzw. mangels Abnahme mit der Inbetriebnahme des Werks.<br/>
                        c) Diese Verjährungsfristen gelten nur, wenn am Vertragsgegenstand Reparaturversuche, Instandsetzungsarbeiten oder technische Änderungen durch den Auftraggeber oder Dritte nicht stattgefunden haben, der Auftraggeber sich vertragsgemäß verhält, der Vertragsgegenstand nur sachgemäß bedient instand gehalten und eingesetzt wurde und offensichtliche Mängel binnen zwei Wochen ab Gefahrübergang, nicht erkennbare Mängel bei Entdeckung, spätestens jedoch innerhalb der Verjährungsfristen dem Auftragnehmer schriftlich angezeigt werden.<br/>
                        d) Im Falle des Vorhandenseins von Mängeln steht dem Auftraggeber ein Zurückbehaltungsrecht dann zu, wenn dies im angemessenen Verhältnis zu den Mängeln und den voraussichtlichen Kosten der Nacherfüllung (insbesondere einer Mangelbeseitigung) steht.</li>
                        <li>Der Auftragnehmer macht darauf aufmerksam, dass eine absolut fehlerfreie Erstellung von Software insbesondere komplexer Softwaresysteme, nach heutigem Stand der Technik nicht bzw. nicht mit zumutbaren Aufwendungen möglich ist.<br/>
                        Gegenstand dieser Mangelhaftung ist ein Programm, das für den üblichen oder nach dem Vertrag vorausgesetzten Gebrauch entsprechend der Programmbeschreibung tauglich ist.<br/>
                        a) Der Auftragnehmer gewährleistet, dass der Programmträger bei der Übergabe an den Auftraggeber keine Material- und Herstellungsfehler hat.<br/>
                        b) Zu beachten ist, dass eine Software während der Nutzung ständigen Verbesserungsbestrebungen unterworfen ist und daher u.U. in bestimmten Abständen ein update erfolgen muss. Dies stellt keinen Mangel dar, sondern ist eine systemimmanente Eigenschaft von Software. Werden Programme für kundeneigene Hardware eingesetzt, erstreckt sich die Mangelhaftung nur auf die gelieferte Software und nicht auf deren Zusammenwirken mit der vom Auftraggeber beigestellten Hard- und Software.</li>
                        <li>Zur Mängelbeseitigung hat der Auftraggeber die nach billigem Ermessen erforderliche Zeit und Gelegenheit zu gewähren.</li>
                        <li>Die Mangelhaftung bezieht sich nicht auf natürliche Abnutzung, ferner nicht auf Schäden, die infolge fehlerhafter oder nachlässiger Behandlung, übermäßiger Beanspruchung, Witterungseinflüssen, höherer Gewalt, ungeeigneter Betriebsmittel, mangelhafter Bauarbeiten, ungeeigneten Baugrundes und solcher chemischen, physikalischen, elektromechanischen oder elektrischen Einflüsse entstehen, die nach dem Vertrag nicht vorausgesetzt sind.</li>
                        <li>Vom Auftraggeber beabsichtigte Nutzungsänderungen sind dem Auftragnehmer anzuzeigen und mit diesem abzustimmen. Unterlässt der Auftraggeber eine solche Anzeige oder Abstimmung, verliert er jeglichen Mangelhaftungsanspruch.</li>
                        <li>Für vom Auftraggeber beigestellte Produkte/Leistungen übernimmt der Auftragnehmer keine Mangelhaftung.</li>
                      </ol>
                      <h2 id="Haftung"><strong>IX. Haftung</strong></h2>
                      <ol>
                        <li>Der Auftragnehmer haftet nur für Vorsatz und grobe Fahrlässigkeit; er schließt seine Haftung für leicht fahrlässige Pflichtverletzungen aus, sofern nicht Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit oder Garantien betroffen sind. Unberührt bleibt ferner die Haftung für die Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Auftraggeber regelmäßig vertrauen darf. Gleiches gilt für Pflichtverletzungen der Erfüllungsgehilfen und Vertreter des Auftragnehmers.</li>
                        <li>Eine darüber hinausgehende Haftung wird nicht übernommen, insbesondere wird nicht für Schäden gehaftet, die als Folge von strafbaren Handlungen (z.B. Raub, Diebstahl, Einbruchdiebstahl) gegenüber Personen, dem Eigentum oder dem Vermögen des Auftraggebers oder Dritten entstehen. Ausgeschlossen sind in jedem Fall Ersatzansprüche für Folgeschäden, z.B. bei Nichtfunktionieren der Anlage, Einbruch, Kosten der Polizei bzw. Feuerwehr sowie ggf. Bewachungsunternehmen bei Gefahrenmeldungen, sofern nicht zwingende gesetzliche Vorschriften über eine Haftung für Vorsatz bzw. grobe Fahrlässigkeit diesen Haftungsbeschränkungen entgegenstehen bzw. soweit nicht Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit geltend gemacht werden.</li>
                        <li>Der Auftragnehmer haftet nicht für Arbeiten seiner Erfüllungsgehilfen, soweit die Arbeiten nicht mit den vereinbarten Lieferungen und Leistungen zusammenhängen oder soweit dieselben vom Auftraggeber direkt veranlasst sind.</li>
                        <li>Etwaige Unregelmäßigkeiten bei der Erfüllung vertraglicher Verpflichtungen des Auftragnehmers sind diesem unverzüglich schriftlich zwecks Abstellung anzuzeigen, andernfalls können Rechte hieraus nicht abgeleitet werden.</li>
                        <li>Beratungen durch Personal des Auftragnehmers oder von ihm beauftragte Vertreter erfolgen unverbindlich und sind nicht Teil des Vertrages. Sie basieren auf dem gegenwärtigen Stand der Erkenntnisse und Erfahrungen des Auftragnehmers und werden nach bestem Wissen erteilt. Haftungsansprüche sind insoweit ausgeschlossen, als dem Auftragnehmer nicht Vorsatz bzw. grobe Fahrlässigkeit nachgewiesen werden kann.</li>
                      </ol>
                      <h2 id="Anwendbares Recht, Erfüllungsort und Gerichtsstand"><strong>X. Anwendbares Recht, Erfüllungsort und Gerichtsstand</strong></h2>
                      <ol>
                        <li>Für die Rechtsbeziehungen zwischen Auftragnehmer und Auftraggeber gilt das Recht der Bundesrepublik Deutschland.</li>
                        <li>Gehört der Vertrag zum Betrieb des Handelsgewerbes eines Kaufmanns, ist ausschließlicher Erfüllungsort und Gerichtsstand der Sitz des Auftragnehmers.</li>
                      </ol>
                      <h2 id="Datenspeicherung"><strong>XI. Datenspeicherung</strong></h2>
                      <p>Der Auftragnehmer ist berechtigt, die im Zusammenhang mit den Geschäftsbeziehungen erhaltenen Daten über den Auftraggeber im Sinne des Bundesdatenschutzgesetzes zu verarbeiten und zu speichern, soweit dies im Rahmen der Durchführung des Vertrages zweckmäßig erscheint.</p>
                      <h2><strong>XII. Sonstiges</strong></h2>
                      <ol>
                        <li>Die Angebote und Planungsunterlagen des Auftragnehmers sind urheberrechtlich geschützt und dürfen ohne dessen schriftliche Genehmigung weder vervielfältigt noch weitergegeben werden. Im Falle der Zuwiderhandlung ist der Auftraggeber zur Schadenersatzleistung verpflichtet.<br/>
                        Die vom Auftragnehmer zur Nutzung überlassenen Programme sind urheberrechtlich geschützt. Der Auftraggeber verpflichtet sich, diese Programme ausschließlich für sich und nur im Rahmen seiner gewerblichen Tätigkeit einzusetzen.<br/>
                        Mit der Entgegennahme der Programme verpflichtet er sich, diese ohne die Zustimmung des Auftragnehmers weder zu vervielfältigen noch vervielfältigen zu lassen sowie von den Programmbeschreibungen keine Kopien zu fertigen oder fertigen zu lassen und keinem unbefugten Dritten die Programme oder Kopien zur Verfügung zu stellen. Im Falle der Zuwiderhandlung ist der Auftraggeber zur Schadensersatzleistung verpflichtet.</li>
                        <li>Bei Übertragungen über das öffentliche Fernsprechnetz oder andere Übertragungsmedien bietet der Auftragnehmer für die Herstellung der Verbindung und die Übertragung der Meldungen keine höhere als die diesem Übertragungsdienst eigene Sicherheit.</li>
                        <li>Gebühren, die vom Netzbetreiber, Polizei, Feuerwehr oder Dritten aufgrund der vereinbarten Lieferungen und Leistungen erhoben werden, gehen zu Lasten des Auftraggebers.</li>
                        <li>Der Auftragnehmer ist berechtigt, sich bei der Erfüllung seiner Verpflichtungen anderer zuverlässiger Unternehmen zu bedienen.</li>
                        <li>Eine Beschaffungspflicht des Auftragnehmers für Ersatzteile besteht nicht, wenn diese nur mit einem unangemessenen wirtschaftlichen Aufwand verbunden ist bzw. eine Beschaffung tatsächlich unmöglich ist.</li>
                        <li>Sollte eine der vorstehenden Bestimmungen rechtsunwirksam sein, so wird dadurch die Geltung der übrigen Bestimmungen nicht berührt. Im Falle der Rechtsunwirksamkeit einer Klausel, ist der Auftraggeber verpflichtet, mit dem Auftragnehmer eine neue Bestimmung zu vereinbaren, die dem mit der unwirksamen Bestimmung verfolgten Zweck am nächsten kommt.</li>
                      </ol>
                    </div>
                </div>
                {/* <div className="column is-narrow-desktop is-hidden-touch">
                    <aside className="nav-content fixed" style={{width: '250px'}}>
                        <p className="title">INHALTSVERZEICHNIS</p>
                        <ul>
                        {h2Tags.map((value) => {
                            return (
                                <li key={value.key}><a href={'#' + value.key}>{value.value}</a></li>
                            );
                        })}
                        </ul>
                    </aside>
                </div> */}
            </div>
        </div>
    </section>
  </Layout>
)

export default AGB
